import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/pages/Home.vue"),
    meta: {
      auth: true,
    },
  },
  /** barang */
  {
    path: "/file/barang",
    name: "file-barang",
    component: () => import("@/pages/FileBarangIndex.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/file/barang/create",
    name: "file-file-barang-create",
    component: () => import("@/pages/FileBarangCreate.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/file/barang/update/:id",
    name: "file-file-barang-update",
    component: () => import("@/pages/FileBarangUpdate.vue"),
    meta: {
      auth: true,
    },
  },
  /** Supplier */
  {
    path: "/file/supplier",
    name: "file-supplier",
    component: () => import("@/pages/FileSupplierIndex.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/file/supplier/create",
    name: "file-file-supplier-create",
    component: () => import("@/pages/FileSupplierCreate.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/file/supplier/update/:id",
    name: "file-file-supplier-update",
    component: () => import("@/pages/FileSupplierUpdate.vue"),
    meta: {
      auth: true,
    },
  },
  /** pelanggan */
  {
    path: "/file/pelanggan",
    name: "file-pelanggan",
    component: () => import("@/pages/FilePelangganIndex.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/file/pelanggan/create",
    name: "file-file-pelanggan-create",
    component: () => import("@/pages/FilePelangganCreate.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/file/pelanggan/update/:id",
    name: "file-file-pelanggan-update",
    component: () => import("@/pages/FilePelangganUpdate.vue"),
    meta: {
      auth: true,
    },
  },
  /** kode-perkiraan */
  {
    path: "/file/kode-perkiraan",
    name: "file-kode-perkiraan",
    component: () => import("@/pages/FileKodePerkiraanIndex.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/file/penyesuaian-persediaan",
    name: "file-penyesuaian-persediaan",
    component: () => import("@/pages/FilePenyesuaianPersediaanIndex.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/file/penyesuaian-persediaan/create",
    name: "file-penyesuaian-persediaan-create",
    component: () => import("@/pages/FilePenyesuaianPersediaanCreate.vue"),
    meta: {
      auth: true,
    },
  },
  /** jenis barang */
  {
    path: "/file/jenis-barang",
    name: "jenis-barang",
    component: () => import("@/pages/FileJenisBrangIndex.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/file/jenis-barang/create",
    name: "jenis-barang-create",
    component: () => import("@/pages/FileJenisBarangCreate.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/file/jenis-barang/update/:id",
    name: "jenis-barang-update",
    component: () => import("@/pages/FileJenisBarangUpdate.vue"),
    meta: {
      auth: true,
    },
  },
  // Pembelian
  {
    path: "/pembelian",
    name: "Pembelian",
    component: () => import(/* webpackChunkName: "store" */ "@/pages/PembelianIndex.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/pembelian/create",
    name: "PembelianCreate",
    component: () => import(/* webpackChunkName: "store" */ "@/pages/PembelianCreate.vue"),
    meta: {
      auth: true,
    },
  },
  // Pembelian
  {
    path: "/penjualan",
    name: "Penjualan",
    component: () => import(/* webpackChunkName: "store" */ "@/pages/PenjualanIndex.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/penjualan/create",
    name: "PenjualanCreate",
    component: () => import(/* webpackChunkName: "store" */ "@/pages/PenjualanCreate.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/transaksiJurnal",
    name: "TransaksiJurnal",
    component: () => import(/* webpackChunkName: "store" */ "@/pages/TransaksiJurnalIndex.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/kas/create",
    name: "KasCreate",
    component: () => import(/* webpackChunkName: "store" */ "@/pages/KasCreate.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/bank/create",
    name: "BankCreate",
    component: () => import(/* webpackChunkName: "store" */ "@/pages/BankCreate.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/btm/create",
    name: "BTMCreate",
    component: () => import(/* webpackChunkName: "store" */ "@/pages/BTMCreateForm.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/transaksiJurnal/create",
    name: "TransaksiJurnalCreate",
    component: () => import(/* webpackChunkName: "store" */ "@/pages/TransaksiJurnalCreate.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/transaksiJurnal/update/:id",
    name: "TransaksiJurnalUpdate",
    component: () => import(/* webpackChunkName: "store" */ "@/pages/TransaksiJurnalUpdate.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/laporan/stock",
    name: "LaporanStock",
    component: () => import(/* webpackChunkName: "store" */ "@/pages/LaporanStock.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/laporan/stock-card",
    name: "LaporanStockCard",
    component: () => import(/* webpackChunkName: "store" */ "@/pages/LaporanStockCard.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/laporan/stock-recap",
    name: "LaporanStockRecap",
    component: () => import(/* webpackChunkName: "store" */ "@/pages/LaporanStockRecap.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/laporan/gl",
    name: "LaporanGL",
    component: () => import(/* webpackChunkName: "store" */ "@/pages/LaporanGL.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/laporan/gl/subBukuBesar",
    name: "LaporanSubBukuBesar",
    component: () => import(/* webpackChunkName: "store" */ "@/pages/LaporanSubBukuBesar.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/laporan/gl/neracaSaldo",
    name: "LaporanNeracaSaldo",
    component: () => import(/* webpackChunkName: "store" */ "@/pages/LaporanNeracaSaldoNeracaSaldo.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/laporan/gl/neraca",
    name: "LaporanNeraca",
    component: () => import(/* webpackChunkName: "store" */ "@/pages/LaporanNeraca.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/laporan/gl/rugiLaba",
    name: "LaporanRugiLaba",
    component: () => import(/* webpackChunkName: "store" */ "@/pages/LaporanRugiLaba.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/laporan/stock/barang",
    name: "LaporanStockBarang",
    component: () => import(/* webpackChunkName: "store" */ "@/pages/LaporanStockBarang.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/laporan/stock/supplier",
    name: "LaporanStockSupplier",
    component: () => import(/* webpackChunkName: "store" */ "@/pages/LaporanStockSupplier.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/laporan/stock/pelanggan",
    name: "LaporanStockPelanggan",
    component: () => import(/* webpackChunkName: "store" */ "@/pages/LaporanStockPelanggan.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/laporan/stock/pembelian",
    name: "LaporanStockPembelian",
    component: () => import(/* webpackChunkName: "store" */ "@/pages/LaporanStockPembelian.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/laporan/stock/transaksi-pembelian",
    name: "LaporanStockTrxPembelian",
    component: () => import(/* webpackChunkName: "store" */ "@/pages/LaporanStockTrxPembelian.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/laporan/stock/penjualan",
    name: "LaporanStockPenjualan",
    component: () => import(/* webpackChunkName: "store" */ "@/pages/LaporanStockPenjualan.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/laporan/stock/transaksi-penjualan",
    name: "LaporanStockTrxPenjualan",
    component: () => import(/* webpackChunkName: "store" */ "@/pages/LaporanStockTrxPenjualan.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/laporan/gl/kodePerkiraan",
    name: "LaporanGLKodePerkiraan",
    component: () => import(/* webpackChunkName: "store" */ "@/pages/LaporanGLKodePerkiraan.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/Login.vue"),
    meta: {
      auth: false,
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/pages/Register.vue"),
    meta: {
      auth: false,
    },
  },
  {
    path: "/file/settings",
    name: "settings",
    component: () => import("@/pages/Settings.vue"),
    meta: {
      auth: false,
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  next();
  // const t = Cookies.get('token');
  // if (to.meta.auth) {
  //   if(t) {
  //     next()
  //   }else {
  //     next({
  //       name: 'login'
  //     })
  //   }
  // }else {
  //   next();
  // }
});

export default router;
